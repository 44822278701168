import { useStaticQuery, graphql } from "gatsby"

const ALL_MEDENSURE_PRODUCTS = graphql`
  query {
    allAirtableMedEnsureFormulary(
      filter: {
        data: {
          ShowOnWebsite: { eq: true }
          Tags: { nin: "Exclude 231004" }
          IndicationOnset: { eq: "CH" }
          RefillTier: { in: ["12", "24"] }
        }
      }
    ) {
      nodes {
        data {
          brand: Brand
          molecule: Molecule
          productTitle: ProductTitle
          rxRequired: RxRequired
          priceList: PriceList
          vatexUnitPrice: VatexUnitPrice
          classification: Classification
          vat: Vat
          classification: Classification
          skuTags: SKUTags
          strength: Strength
          form: Form
          drugName: DrugName
        }
      }
    }
  }
`

export const useMedensureProducts = () => {
  const data = useStaticQuery(ALL_MEDENSURE_PRODUCTS)

  return data
}
