import React from "react"
import classNames from "classnames"

import Button from "../../Elements/Button"

import styles from "../utils/medensure.module.scss"

const MedEnsureModal = ({ dispatch, clientWebsite }) => {
  return (
    <div
      className={classNames(
        styles["medensurePopupModal"],
        "has-background-white px-2 pt-2 pb-3"
      )}
    >
      <p>
        This form is intended for employees who requested medicines before and
        are looking to update those medicines. To request new medicines, visit{" "}
        <a
          className={classNames(
            styles["medensurePopupModal__emailLink"],
            "has-text-secondary"
          )}
          href={`https://${clientWebsite}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {clientWebsite}.
        </a>
      </p>
      <Button
        size="medium"
        className={classNames(
          styles["medensurePopupModal__confirmButton"],
          "mt-2 bg-secondary has-text-white"
        )}
        onClick={() => {
          dispatch({ type: "HIDE_MODAL" })
          dispatch({ type: "HIDE_SLIDING_MODAL" })
        }}
      >
        Okay
      </Button>
    </div>
  )
}

export default MedEnsureModal
