export const getClientWebsite = (client) => {
  switch (client?.toLowerCase()) {
    case "maxicare":
      return "maxicare.medgrocer.com"
    case "accenture":
      return "accenture.medgrocer.com"
    case "foundever":
      return "foundever.medgrocer.com"
    default:
      return ""
  }
}
