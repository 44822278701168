export const reformatAirtableData = (dataMapElement) => {
  // Change data format from Airtable to fit fuzzySearch
  let jsonFormat = {
    drugName: dataMapElement?.drugName?.[0],
    brand: dataMapElement?.brand?.[0],
    molecule: dataMapElement?.molecule?.[0],
    price_list: {
      name: dataMapElement?.priceList,
      vat: dataMapElement?.vat,
      vatex_unit_price: dataMapElement?.vatexUnitPrice,
    },
    productTitle: dataMapElement.productTitle?.[0],
    rxRequired: dataMapElement.rxRequired?.[0],
    classification: dataMapElement?.classification?.[0],
    skuTags: dataMapElement?.skuTags || [],
    strength: dataMapElement?.strength?.[0] || "",
    form: dataMapElement?.form?.[0] || "",
  }

  return jsonFormat
}
